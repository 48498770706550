<template>
  <div class="content">
    <div
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >
      <section class="text-center col-lg-8 col-sm-12">
        <div class="header text-center" style="margin-bottom: 10px">
          <HeadImage
            :image="mainImage"
            :clamp="true"
            title="LOS CABOS NYE"
            align="text-center"
            caption="New Year Celebration 2024-2025"
            color="#efefef"
          />

          <div class="card centered" style="width: 70%">
            <p>

            <strong>Los Cabos is the perfect destination to celebrate the end of the year</strong>
            <br>Its clear waters, natural beauty, and vibrant <router-link :to="{path: '/los-cabos-nightlife'}">nightlife</router-link> will make you want to extend your trip all year long.
            Whether you're seeking <router-link :to="{path: '/'}">parties</router-link>, relaxation, or a bit of both, Los Cabos has something for everyone.

              <br>
              <br>
              <mini-carrousel folder="cabo-festival-nye"></mini-carrousel>

              <br>
              <strong>During this 2024-2025 New Year's Eve season</strong>, clubs, restaurants, beach clubs, and nightclubs create ad-hoc events & show for the occasion.

              <br>We have gathered them all for you here!
            Curious? Confused? Drop us a line.
            </p>

            <p>Want to Party Harder?
              <br>Check this out: <a href="https://www.tulum.party/tulum-new-year-festival-2024">TULUM NYE 2025</a></p>
          </div>


          <div
            class="text-center"
            style="background: linear-gradient(45deg, black, #dd7c53)"
          >

            <time-line >
              <time-line-item
                :full="true"
                v-for="(event, idx) in festivalParties"
                :key="event._id"
                :inverted="idx % 2 === 0"
                :badge-type="badgeType(idx)"
              >
                <div slot="content">
                  <EventCard
                    v-bind:event="event"
                    :key="event._id"
                    :idx="idx"
                  ></EventCard>
                </div>
              </time-line-item>
            </time-line>
            <div class="row">
              <SmartMenu
                :links="links"
                top-section-name="NYE 2025"
                cta-text="Bookings"
                :primary-color="secondaryColor"
              >
                <div class="card centered" style="max-width: 70%">
                  <p>
                    <strong>Cabo San Lucas</strong> is ready to amaze you this <strong>New Year's Eve.</strong>
                    From dusk till dawn, Cabo San Lucas promises an unforgettable celebration.
                  </p>
                </div>

              </SmartMenu>
            </div>
          </div>
        </div>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <div class="row">
          <SmartMenu
            :links="links"
            top-section-name="NYE 2025"
            cta-text="Bookings"
            :primary-color="secondaryColor"
          >
            <div class="card">
              <p>
                <strong>Cabo San Lucas</strong> is ready to amaze you this New Year's Eve.
                From dusk till dawn, Cabo San Lucas promises an unforgettable celebration.
              </p>
            </div>

          </SmartMenu>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import {TimeLine, TimeLineItem} from 'src/components';
import EventCard from '../../components/EventCard/EventCard';
import moment from 'moment';
import HeadImage from '@/components/HeadImage/HeadImage';
// import MobileDjCollection from "@/components/Cards/MobileDjCollection";
// import BrandCollection from "@/components/Cards/BrandCollection";
import SmartMenu from '@/components/SmartMenu/SmartMenu';
import MiniCarrousel from "@/components/MiniCarrousel";

export default {
  name: 'CaboNye2024',
  created: function () {
    this.$store.dispatch('getFestival', {slug: 'los-cabos-party-new-year-2025'});
    this.$store.dispatch('getLinks', {slug: 'los-cabos-party-new-year-2025'});
    },

  props: ['slug'],
  metaInfo() {
    return {
      title:
        'Los Cabos · NYE Celebration 2025 | Dinners & Parties',
      link: [
        {
          rel: 'canonical',
          href: 'https://www.cabo.party/los-cabos-party-new-year-celebration-2025',
        },
      ],
      meta: [
        {
          name: 'description',
          content: `Los Cabos · New Year Celebration | Top Events | Reserve Your Spot`,
        },
        {
          name: 'keywords',
          content: `Los Cabos, Cabos San Lucas, New Year, NYE, NYE 2025`,
        },
        {
          property: 'og:title',
          content: `Los Cabos · NYE Celebration 2025 | Dinners & Parties`,
        },
        {
          property: 'og:url',
          href: 'https://www.cabo.party/los-cabos-party-new-year-celebration-2025',
        },
        {
          property: 'og:description',
          content: `Los Cabos · New Year Celebration | Top Events`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content:
            'https://imgix.cosmicjs.com/aeb7b600-95b1-11ef-b5a0-93db72e2be98-nye-cabo-fireworks.jpg',
        },
      ],
    };
  },
  data: () => {
    return {
      view: 'detailed',
      secondaryColor: '#2c2f2f',
      mainImage:
        'https://imgix.cosmicjs.com/aeb7b600-95b1-11ef-b5a0-93db72e2be98-nye-cabo-fireworks.jpg',

    };
  },
  components: {
    MiniCarrousel,
    SmartMenu,
    TimeLine,
    TimeLineItem,
    EventCard,
    HeadImage,
  },
  methods: {
    formatDate(date) {
      return moment(date).format(' DD MMM ');
    },
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    onQuickReserveClick() {
      window.ga(
        'send',
        'event',
        'reservations',
        'NYE Info Request',
        'Main',
        30
      );
      this.$rollbar.info('NYE Info Request');
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    },
  },
  computed: {
    links: {
      get() {
        return this.$store.state.links || [];
      },
    },
    festivalParties: {
      get() {
        return [...this.$store.state.festival.metadata.parties]
          .sort((a, b) => {
            let time_a = a.metadata.start_time || '00:00';
            let time_b = b.metadata.start_time || '00:00';
            return moment.utc(a.metadata.date + 'T' + time_a) >
            moment.utc(b.metadata.date + 'T' + time_b)
              ? 1
              : -1;
          })
          .filter((item) => {
            let partyDateEndOfDay = moment.utc(item.metadata.date).endOf('day');
            let now = moment().startOf('day');
            return partyDateEndOfDay >= now;
          })
      },
    },
  },
};
</script>
